var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "carbonTemplateDialog",
        "header-class": "header-class-modal-doc-package",
        title: _vm.titleCaption,
        size: "xl",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "cancel-variant": "outline-primary",
        "ok-disabled": _vm.loadingSubmit,
        "cancel-disabled": _vm.loadingSubmit,
      },
      on: {
        cancel: _vm.emitEventClose,
        hidden: _vm.emitEventClose,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleOk.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: cancel },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(43, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "md",
                        variant: "primary",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: ok },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _vm.loadingSubmit
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: `${_vm.loadingSubmit ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.isCopyAction === true
                                      ? _vm.FormMSG(87, "Copy")
                                      : _vm.FormMSG(59, "Save")
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { ref: "content-carbon-template-dialog-ref" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "5" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(187, "Name/Description (optional)"),
                      },
                    },
                    [
                      _c("b-form-input", {
                        model: {
                          value: _vm.dataToSend.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "name", $$v)
                          },
                          expression: "dataToSend.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "7" } }, [
                _c(
                  "div",
                  { staticClass: "float-right mt-4" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { switch: "", size: "lg" },
                        model: {
                          value: _vm.dataToSend.disable,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "disable", $$v)
                          },
                          expression: "dataToSend.disable",
                        },
                      },
                      [
                        _c("div", { staticClass: "mt-1" }, [
                          _vm._v(_vm._s(_vm.FormMSG(514, "Disable"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("b-alert", { attrs: { show: "", variant: "info" } }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("div", { staticClass: "fs-16 fw-700 mr-1" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.FormMSG(53, "Notes:")) +
                    "\n\t\t\t\t"
                ),
              ]),
              _c("div", [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        56,
                        "Notes: the combination of all criteria entered (AND logic) should be verified to apply the automation result on a green entry."
                      )
                    ) +
                    "\n\t\t\t\t"
                ),
              ]),
            ]),
          ]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticStyle: { "border-right": "1px solid #cad0d7" },
                  attrs: { cols: "5" },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("div", { staticClass: "fs-18 fw-700 text-center" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(165, "Criteria")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(158, "Cost centers"),
                              },
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  placeholder: _vm.FormMSG(
                                    195,
                                    "Enter cost codes ..."
                                  ),
                                  rows: "3",
                                  "max-rows": "3",
                                },
                                model: {
                                  value: _vm.dataToSend.costCenters,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataToSend, "costCenters", $$v)
                                  },
                                  expression: "dataToSend.costCenters",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "invalid-feedback text-color-blue-streak",
                                },
                                [
                                  _c(_vm.getLucideIcon("Info"), {
                                    tag: "component",
                                    attrs: { size: 16 },
                                  }),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          1241,
                                          'Enter cost codes separate by a "," - For instance: 5311,53112,71111'
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("supplier-selector", {
                            staticClass: "mb-3",
                            attrs: {
                              title: _vm.FormMSG(250, "Add supplier details"),
                              label:
                                _vm.$screen.width > 576
                                  ? _vm.FormMSG(481, "Supplier")
                                  : "",
                              placeholder: _vm.FormMSG(13, "Filter by name"),
                              addlabel: _vm.FormMSG(5442, "New supplier"),
                              "update-label": _vm.FormMSG(40, "Update"),
                              "show-map": false,
                              type: "all",
                              "supplier-id": _vm.dataToSend.supplierId,
                              "use-new-design": "",
                              version: "1.0",
                            },
                            on: {
                              change: _vm.handleSupplierChange,
                              "supplier:unselected": _vm.handleSupplierChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(168, "Keyword 1") } },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  placeholder: _vm.FormMSG(
                                    169,
                                    "Enter a keyword ..."
                                  ),
                                  rows: "3",
                                  "max-rows": "3",
                                },
                                model: {
                                  value: _vm.dataToSend.keyWordOne,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataToSend, "keyWordOne", $$v)
                                  },
                                  expression: "dataToSend.keyWordOne",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(598, "Keyword 2") } },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  placeholder: _vm.FormMSG(
                                    169,
                                    "Enter a keyword ..."
                                  ),
                                  rows: "3",
                                  "max-rows": "3",
                                },
                                model: {
                                  value: _vm.dataToSend.keyWordTwo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataToSend, "keyWordTwo", $$v)
                                  },
                                  expression: "dataToSend.keyWordTwo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(599, "Keyword 3") } },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  placeholder: _vm.FormMSG(
                                    169,
                                    "Enter a keyword ..."
                                  ),
                                  rows: "3",
                                  "max-rows": "3",
                                },
                                model: {
                                  value: _vm.dataToSend.keyWordThree,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataToSend,
                                      "keyWordThree",
                                      $$v
                                    )
                                  },
                                  expression: "dataToSend.keyWordThree",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { value: true, "unchecked-value": false },
                              model: {
                                value: _vm.dataToSend.searchInDescOnly,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataToSend,
                                    "searchInDescOnly",
                                    $$v
                                  )
                                },
                                expression: "dataToSend.searchInDescOnly",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      190,
                                      "Search keyword only description"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "7" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("div", { staticClass: "fs-18 fw-700 text-center" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(166, "Result")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end mt-2" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                id: "setCat",
                                text: _vm.labelSetCat,
                                "no-caret": "",
                                dropleft: "",
                                variant: "success",
                                size: "sm",
                              },
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.useSetCatInKeyword(
                                        "keyWordOne"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(1021, "Used as keyword 1")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.useSetCatInKeyword(
                                        "keyWordTwo"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(1022, "Used as keyword 2")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.useSetCatInKeyword(
                                        "keyWordThree"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(1023, "Used as keyword 3")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "fieldset",
                          {
                            staticClass: "my-0 py-0 pb-0 mt-1",
                            class: `${
                              _vm.$screen.width <= 576
                                ? "card-inside"
                                : "scheduler-border"
                            }`,
                          },
                          [
                            _c(
                              "legend",
                              {
                                staticClass:
                                  "text-color-rhapsody-in-blue-2 fw-400",
                                class: `${
                                  _vm.$screen.width <= 576
                                    ? "card-inside"
                                    : "scheduler-border"
                                }`,
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(30, "Green details")) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "pt-3 pb-4" },
                              [
                                _c("co-two-clap", {
                                  ref: "coTwoClapRef",
                                  attrs: {
                                    "parent-id": 0,
                                    "entity-type": 9,
                                    "for-create": true,
                                    "is-submit": false,
                                    "external-data":
                                      _vm.isEdit === true ? _vm.dataToSend : {},
                                    "for-carbon-template": "",
                                  },
                                  on: {
                                    "co-two-clap:change":
                                      _vm.handleCoTwoClapChange,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.dataToSend.coTwoCategory === 2
                    ? _c(
                        "b-row",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      457,
                                      "Average cost per night"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        on: {
                                          change:
                                            _vm.handleChangeAverageCostPerNight,
                                        },
                                        model: {
                                          value: _vm.dataToSend.unitAmountTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToSend,
                                              "unitAmountTotal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataToSend.unitAmountTotal",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataToSend.coTwoCategory === 4
                    ? _c(
                        "b-row",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      458,
                                      "Average cost per kilometer"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          disabled: _vm.dataToSend.boolValue,
                                        },
                                        on: {
                                          change:
                                            _vm.handleChangeAverageTransport,
                                        },
                                        model: {
                                          value: _vm.dataToSend.unitAmountTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToSend,
                                              "unitAmountTotal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataToSend.unitAmountTotal",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataToSend.coTwoCategory === 12
                    ? _c(
                        "b-row",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      154,
                                      "Average cost per km"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        on: {
                                          change:
                                            _vm.handleChangeAverageTransportLodges,
                                        },
                                        model: {
                                          value: _vm.dataToSend.unitAmountTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToSend,
                                              "unitAmountTotal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataToSend.unitAmountTotal",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataToSend.coTwoCategory === 13
                    ? _c(
                        "b-row",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      139,
                                      "Average cost per liter"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        on: {
                                          change: _vm.handleChangeAverateFuel,
                                        },
                                        model: {
                                          value: _vm.dataToSend.unitAmountTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToSend,
                                              "unitAmountTotal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataToSend.unitAmountTotal",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataToSend.coTwoCategory === 3
                    ? _c(
                        "b-row",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(868, "Reason") },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.reasonTypes },
                                    on: { change: _vm.handleChangeReason },
                                    model: {
                                      value: _vm.dataToSend.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataToSend, "type", $$v)
                                      },
                                      expression: "dataToSend.type",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.showSubTypeEnergy
                            ? _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "6",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.subTypeEnergyCaption,
                                      },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "setName",
                                          reduce: (option) => +option.id,
                                          options: _vm.locationsPremisesList,
                                          placeholder: _vm.FormMSG(
                                            915,
                                            "Please select ..."
                                          ),
                                        },
                                        model: {
                                          value: _vm.dataToSend.locationId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToSend,
                                              "locationId",
                                              $$v
                                            )
                                          },
                                          expression: "dataToSend.locationId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataToSend.coTwoCategory === 3 ||
                  _vm.dataToSend.coTwoCategory === 1 ||
                  _vm.dataToSend.coTwoCategory === 15
                    ? _c(
                        "b-row",
                        {
                          class:
                            _vm.dataToSend.coTwoCategory !== 3 ? "mt-5" : "",
                        },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(571, "Energy type"),
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.energyTypeOptions },
                                    on: { change: _vm.handleChangeEnergyType },
                                    model: {
                                      value: _vm.dataToSend.subType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataToSend, "subType", $$v)
                                      },
                                      expression: "dataToSend.subType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      482,
                                      "Average cost per unit"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        on: {
                                          change: _vm.handleChangeAverageEnergy,
                                        },
                                        model: {
                                          value: _vm.dataToSend.unitAmountTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToSend,
                                              "unitAmountTotal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataToSend.unitAmountTotal",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataToSend.coTwoCategory === 5
                    ? _c(
                        "b-row",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(807, "Waste type"),
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.wasteTypeOptions },
                                    on: { change: _vm.handleChangeWasteType },
                                    model: {
                                      value: _vm.dataToSend.subType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataToSend, "subType", $$v)
                                      },
                                      expression: "dataToSend.subType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      808,
                                      "Average cost per kg"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        on: {
                                          change: _vm.handleChangeAverageWaste,
                                        },
                                        model: {
                                          value: _vm.dataToSend.unitAmountTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToSend,
                                              "unitAmountTotal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataToSend.unitAmountTotal",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataToSend.coTwoCategory === 6
                    ? _c(
                        "b-row",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(461, "Meal type"),
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.mealTypeOptions },
                                    on: { change: _vm.handleChangeMealType },
                                    model: {
                                      value: _vm.dataToSend.subType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataToSend, "subType", $$v)
                                      },
                                      expression: "dataToSend.subType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      462,
                                      "Average cost per meal"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        on: {
                                          change: _vm.handleChangeAverageMeal,
                                        },
                                        model: {
                                          value: _vm.dataToSend.unitAmountTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToSend,
                                              "unitAmountTotal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataToSend.unitAmountTotal",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataToSend.coTwoCategory === 10
                    ? _c(
                        "b-row",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(125, "Vehicle type"),
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.vehicleTypeOptions },
                                    model: {
                                      value: _vm.dataToSend.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataToSend, "type", $$v)
                                      },
                                      expression: "dataToSend.type",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      126,
                                      "Average cost per unit"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        on: {
                                          change:
                                            _vm.handleChangeAverateVehicleShooting,
                                        },
                                        model: {
                                          value: _vm.dataToSend.unitAmountTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToSend,
                                              "unitAmountTotal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataToSend.unitAmountTotal",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showRawTextEditor
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { staticClass: "mb-0" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "pb-0 mb-0",
                                  attrs: { for: "type" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center fs-12",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(189, "Raw text")) +
                                          " \n\t\t\t\t\t\t\t\t"
                                      ),
                                      _c(
                                        "info-tooltip",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "2px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  644,
                                                  "Data coming from the creation by a document"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("b-form-textarea", {
                                staticClass: "bg-color-white",
                                attrs: {
                                  value: _vm.rawTextData.text,
                                  rows: "7",
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.rawTextFileDownloaded
                            ? _c("div", { staticClass: "float-right" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.left.html",
                                        value: _vm.FormMSG(521, "Copy text"),
                                        expression: "FormMSG(521, 'Copy text')",
                                        modifiers: {
                                          hover: true,
                                          left: true,
                                          html: true,
                                        },
                                      },
                                    ],
                                    staticClass:
                                      "btn-transparent text-color-rhapsody-in-blue",
                                    attrs: { type: "button" },
                                    on: { click: _vm.handleCopyRawText },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("Download"), {
                                      tag: "component",
                                      attrs: { size: 20 },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                show: _vm.rawTextFileDownloaded,
                                variant: "success",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      619,
                                      "Downloaded file successfully!"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }